<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Admin</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <base-title></base-title>
      <div class="row">
        <div class="col-4">
          <StreamBarcodeReader
            @decode="onDecode"
            @loaded="onLoaded"
          ></StreamBarcodeReader>
        </div>
        <div class="col-8">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form @submit.prevent="adminInsert" enctype="multipart/form-data">
              <div class="card-body">
                <div class="form-group">
                  <label>APPLICATION ID</label>
                  <input
                    type="text"
                    readonly
                    class="form-control"
                    v-model="form.IDuser"
                  />
                  <small class="text-danger" v-if="errors.IDuser">{{
                    errors.IDuser[0]
                  }}</small>
                </div>
              </div>
              <div class="card-footer text-right">
                <button
                  class="btn btn-primary mr-1"
                  :disabled="isSubmitting"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import { StreamBarcodeReader } from "vue-barcode-reader";

export default {
  name: "FormAdmin",
  components: { StreamBarcodeReader },
  data() {
    return {
      name: "Form Code Admin",
      form: {
        IDuser: "",
        id: "",
      },
      errors: {},
      role: [],
      isSubmitting: false,

      token: localStorage.getItem("token"),
    };
  },
  mounted() {
    axios
      .get(this.$store.state.api + "role", {
        headers: { Authorization: this.$store.state.token },
      })
      .then((res) => (this.role = res.data.data))
      .catch((err) => console.log(err));
  },
  methods: {
    onDecode(result) {
      this.form.IDuser = result;
      this.form.id = this.$route.params.id;
    },
    adminInsert() {
      this.isSubmitting = true;
      axios
        .post(this.$store.state.api + "user_integration", this.form, {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then(() => {
          this.$noty.success("Your admin has been saved!");
          this.$router.push({ name: "Admin" });
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.isSubmitting = false;
        });
    },
  },
};
</script>
<style>
.btn-primary {
  color: #fff;
}
</style>
