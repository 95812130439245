import Admin from "./Admin.vue"
import AdminCreate from "./Create.vue"
import AdminApplicationCode from "./Code.vue"
import AdminEdit from "./Edit.vue"
import AdminPayroll from "./AdminPayroll.vue"


export default [
    {
        path: "/admin",
        component: Admin,
        name: "Admin"
    },
    {
        path: "/admin/create",
        component: AdminCreate,
        name: "AdminCreate"
    },
    {
        path: "/admin/code/:id",
        component: AdminApplicationCode,
        name: "AdminApplicationCode"
    },
    {
        path: "/admin/edit/:id",
        component: AdminEdit,
        name: "AdminEdit"
    },
    {
        path: "/admin/create/payroll",
        component: AdminPayroll,
        name: "AdminPayroll"
    },
]