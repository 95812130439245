<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Admin</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <base-title></base-title>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form @submit.prevent="adminInsert" enctype="multipart/form-data">
              <div class="card-body">
                <div class="form-group">
                  <label>Username</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.username"
                    required
                  />
                  <small class="text-danger" v-if="errors.username">{{
                    errors.username[0]
                  }}</small>
                </div>
                <div class="form-group">
                  <label>Email</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.email"
                    required
                  />
                  <small class="text-danger" v-if="errors.email">{{
                    errors.email[0]
                  }}</small>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label>Password</label>
                    <input
                      type="password"
                      class="form-control"
                      v-model="form.password"
                      required
                    />
                    <small class="text-danger" v-if="errors.password">{{
                      errors.password[0]
                    }}</small>
                  </div>
                  <div class="form-group col-md-6">
                    <label>Password Confirmation</label>
                    <input
                      type="password"
                      class="form-control"
                      v-model="form.password_confirmation"
                      v-on:keyup="passwordConfirmation"
                      required
                    />
                    <small class="text-danger" v-if="errorPsw">
                      {{ errorPsw }}</small
                    >
                  </div>
                </div>
                <div class="form-group">
                  <label>Avatar</label>
                  <input
                    type="file"
                    accept="image/*"
                    class="form-control"
                    @change="onImageSelected"
                    required
                  />
                  <img :src="form.avatar" alt="" style="height: 0px" />
                  <small class="text-danger" v-if="errors.avatar">{{
                    errors.avatar[0]
                  }}</small>
                </div>
                <div class="form-group">
                  <label>User Role</label>
                  <select class="custom-select" v-model="form.role_id" required>
                    <option selected="" value="null">
                      Open this select menu
                    </option>
                    <option v-for="r in role" :key="r.id" :value="r.id">
                      {{ r.name }}
                    </option>
                  </select>
                  <small class="text-danger" v-if="errors.avatar">{{
                    errors.avatar[0]
                  }}</small>
                </div>
                <div class="form-group">
                  <label>Venue</label>
                  <select
                    class="custom-select"
                    v-model="form.venue_id"
                    required
                  >
                    <option selected="" value="null">
                      Open this select menu
                    </option>
                    <option v-for="v in venue" :key="v.id" :value="v.id">
                      {{ v.title }}
                    </option>
                  </select>
                  <small class="text-danger" v-if="errors.avatar">{{
                    errors.avatar[0]
                  }}</small>
                </div>
              </div>
              <div class="card-footer text-right">
                <button
                  class="btn btn-primary mr-1"
                  :disabled="isSubmitting"
                  v-if="form.password == form.password_confirmation"
                  type="submit"
                >
                  Submit
                </button>
                <button class="btn btn-secondary" type="reset">Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';

export default {
  name: 'FormAdmin',
  data() {
    return {
      name: 'Form Admin',
      form: {
        username: null,
        email: null,
        password: null,
        password_confirmation: null,
        avatar: null,
        role_id: 'null',
        venue_id: null,
      },
      errorPsw: null,
      errors: {},
      role: [],
      venue: [],
      isSubmitting: false,

      token: localStorage.getItem('token'),
    };
  },
  mounted() {
    this.getVenue();
    axios
      .get(this.$store.state.api + 'role', {
        headers: { Authorization: this.$store.state.token },
      })
      .then((res) => (this.role = res.data.data))
      .catch((err) => console.log(err));
  },
  methods: {
    getVenue() {
      axios
        .get(this.$store.state.api + 'venue', {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then((res) => {
          this.venue = res.data.data;
        })
        .catch((err) => console.log(err));
    },
    onImageSelected(event) {
      let avatar = event.target.files[0];
      if (avatar.size > 1000000) {
        // Notification.success();
        console.log('error');
      } else {
        let reader = new FileReader();
        reader.onload = () => {
          this.form.avatar = avatar;
        };
        reader.readAsDataURL(avatar);
      }
    },
    passwordConfirmation() {
      if (this.form.password != this.form.password_confirmation) {
        this.errorPsw = 'Password not correct';
        console.log('password tidak sama');
      } else {
        this.errorPsw = null;
      }
    },
    adminInsert() {
      this.isSubmitting = true;
      let formData = new FormData();
      formData.set('username', this.form.username);
      formData.set('email', this.form.email);
      formData.set('password', this.form.password);
      formData.set('role_id', this.form.role_id);
      formData.set('avatar', this.form.avatar);
      formData.set('venue_id', this.form.venue_id);

      axios
        .post(this.$store.state.api + 'admin/store', formData, {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then(() => {
          this.$noty.success('Your admin has been saved!');
          this.$router.push({ name: 'Admin' });
        })
        // .catch((error) => console.log(error.response.data));
        .catch((error) => {
          this.errors = error.response.data;
          this.isSubmitting = false;
        });
    },
  },
};
</script>
<style>
.btn-primary {
  color: #fff;
}
</style>
