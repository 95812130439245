<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Admin</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <base-title></base-title>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form @submit.prevent="adminUpdate" enctype="multipart/form-data">
              <div class="card-body">
                <div class="form-group">
                  <label>Username</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.username"
                    required
                  />
                  <small class="text-danger" v-if="errors.username">{{
                    errors.username[0]
                  }}</small>
                </div>
                <div class="form-group">
                  <label>Email</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.email"
                    required
                  />
                  <small class="text-danger" v-if="errors.email">{{
                    errors.email[0]
                  }}</small>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-6">
                    <label>Password</label>
                    <input
                      type="password"
                      class="form-control"
                      v-model="form.password"
                    />
                    <small class="text-danger" v-if="errors.password">{{
                      errors.password[0]
                    }}</small>
                  </div>
                  <div class="form-group col-md-6">
                    <label>Password Confirmation</label>
                    <input
                      type="password"
                      class="form-control"
                      v-model="form.password_confirmation"
                      v-on:keyup="passwordConfirmation"
                    />
                    <small class="text-danger" v-if="errorPsw">
                      {{ errorPsw }}</small
                    >
                  </div>
                </div>
                <div class="form-group">
                  <label>Avatar</label>
                  <input
                    type="file"
                    accept="image/*"
                    class="form-control"
                    @change="onImageSelected"
                  />
                  <img :src="form.avatar" alt="" style="height:0px" />
                  <small class="text-danger" v-if="errors.avatar">{{
                    errors.avatar[0]
                  }}</small>
                </div>
                <div class="form-group">
                  <label>User Role</label>
                  <select class="custom-select" v-model="form.role_id" required>
                    <option selected="" value=""
                      >Open this select menu</option
                    >
                    <option v-for="r in role" :key="r.id" :value="r.id">{{
                      r.name
                    }}</option>
                  </select>
                  <small class="text-danger" v-if="errors.role_id">{{
                    errors.role_id[0]
                  }}</small>
                </div>
                <div class="form-group">
                  <label>User Venue</label>
                  <select class="custom-select" v-model="form.venue_id">
                    <option selected="" value="">Open this select menu</option>
                    <option
                      v-for="venue in venues"
                      :key="venue.id"
                      :value="venue.id"
                      >{{ venue.title }}</option
                    >
                  </select>
                  <small class="text-danger" v-if="errors.venue_id">{{
                    errors.venue_id[0]
                  }}</small>
                </div>
              </div>
              <div class="card-footer text-right">
                <button
                  class="btn btn-primary mr-1"
                  :disabled="isSubmitting"
                  v-if="form.password == form.password_confirmation"
                  type="submit"
                >
                  Submit
                </button>
                <button class="btn btn-secondary" type="reset">Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import axios from "axios";
import { apiGet } from "../../../services/api";
export default {
  name: "EditAdmin",
  data() {
    return {
      name: "Edit Admin",
      form: {
        username: null,
        email: null,
        password: null,
        password_confirmation: null,
        avatar: null,
        role_id: null,
        venue_id: null,
      },
      errorPsw: null,
      role: [],
      isSubmitting: false,
      errors: {},
      venues: [],
    };
  },
  mounted() {
    let id = this.$route.params.id;
    axios
      .get(this.$store.state.api + "admin/" + id, {
        headers: {
          Authorization: this.$store.state.token,
        },
      })
      .then((res) => {
        this.form.username = res.data.data.username;
        this.form.email = res.data.data.email;
        this.form.role_id = res.data.data.role.id;
        this.form.venue_id = res.data.data.venue_id;
      })
      .catch((err) => console.log(err));
    axios
      .get(this.$store.state.api + "role", {
        headers: { Authorization: this.$store.state.token },
      })
      .then((res) => {
        this.role = res.data.data;
      })
      .catch((err) => console.log(err));
    this.getVenue();
  },
  methods: {
    onImageSelected(event) {
      this.form.avatar = event.target.files[0];
    },
    passwordConfirmation() {
      if (this.form.password != this.form.password_confirmation) {
        this.errorPsw = "Password not correct";
        console.log("password tidak sama");
      } else {
        this.errorPsw = null;
      }
    },
    getVenue() {
      apiGet("venue")
        .then((result) => {
          this.venues = result.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    adminUpdate() {
      this.isSubmitting = true;
      let id = this.$route.params.id;
      let formData = new FormData();
      formData.set("username", this.form.username);
      formData.set("role_id", this.form.role_id);
      formData.set("email", this.form.email);
      if (this.form.venue_id) {
        formData.set("venue_id", this.form.venue_id);
      }else{
        formData.set("venue_id", "ALL_VENUE");
      }
      if (this.form.password != null) {
        formData.set("password", this.form.password);
      }
      if (this.form.avatar != null) {
        formData.set("avatar", this.form.avatar);
      }
      axios
        .post(this.$store.state.api + "admin/update/" + id, formData, {
          headers: {
            Authorization: this.$store.state.token,
          },
        })
        .then(() => {
          this.$noty.success("Your admin has been updated!");
          this.$router.push({ name: "Admin" });
        })
        .catch((error) => {
          this.errors = error.response.data;
          this.isSubmitting = false;
        });
    },
  },
};
</script>
